export const selectStyles = ({ themeColors }) => {
  return {
    control: (provided) => {
      return {
        ...provided,
        // background: themeColors['Secondary-Background'],
        // borderColor: themeColors['Secondary-Grey'],
        // borderRadius: '5px',
        '&:hover': {
          borderColor: themeColors['Secondary-1'],
        },
        // boxShadow: 'none',
        // height: '40px',
        // minHeight: '40px',
      };
    },
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeColors['Primary-Text'],
      fontFamily: themeColors['ParagraphFont'],
      fontSize: '14px',
      fontWeight: '400',
    }),
    option: (provided, state) => {
      const { isSelected, isFocused } = state;
      return {
        ...provided,
        color: isSelected
          ? themeColors['Secondary-Background']
          : themeColors['Primary-Text'],
        // borderBottom: `1px solid ${themeColors['Primary-Grey']}`,
        fontSize: '16px',
        fontFamily: themeColors['ParagraphFont'],
        fontWeight: '400',
        whiteSpace: 'pre-wrap',
        backgroundColor: isSelected
          ? themeColors['Secondary-2']
          : isFocused
            ? `${themeColors['Secondary-2']}16`
            : 'transparent',
        '&:active': {
          backgroundColor: `${themeColors['Secondary-2']}`,
          color: themeColors['Secondary-Background'],
        },
        textAlign: 'left',
        paddingLeft: '15px',
        lineHeight: 1.5,
        borderBottom: '1px solid #cccccc80',
        cursor: 'pointer'
      };
    },
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderRadius: '0 0 5px 5px',
      borderWidth: '0 1px 1px',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderLeft: 'solid 1px transparent',
      borderRight: 'solid 1px transparent',
      textTransform: 'capitalize',
      '&:last-child': {
        borderBottom: 'solid 1px transparent',
      },
    }),
  };
};
