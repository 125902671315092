import React, { useState } from 'react';
import { components as selectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import { MultipleValuesContainer as MultiValue } from './MultiValueComponent';
import debounce from 'debounce-promise';
import { selectStyles } from './selectStyles';

const AutoSuggestionSelectComponent = (props) => {
  const themeColors = {};
  const {
    inputClassName,
    components,
    options,
    clearable = false,
    fetchOptions,
    ...otherProps
  } = props;
  const [selectClassName, setSelectClassName] = useState('');

  const customStyles = selectStyles({ themeColors });

  const Input = (props) => {
    return (
      <selectComponents.Input
        value="med"
        {...props}
        maxLength={50}
        minLength={3}
      />
    );
  };
  const defaultComponents = { MultiValue, Input, MultiValueRemove: () => null };

  const debounceFunc = debounce(async (inputValue) => {
    if (inputValue.length < 3) {
      return [];
    } else {
      try {
        const res = await fetchOptions(inputValue);
        if (res?.length) {
          return [...res];
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    }
  }, 300);

  const noOptionsMessage = (input) => {
    if (input.inputValue.length === 0) {
      return 'Type to search';
    } else if (input.inputValue.length < 3) {
      return 'Search input must be at least 3 characters';
    } else {
      return 'No options';
    }
  };

  return (
    <>
      {props.id && props.labelText && (
        <label className="sr-only" htmlFor={props.id && props.id}>
          {props.labelText && props.labelText}
        </label>
      )}
      <AsyncSelect
        {...otherProps}
        styles={customStyles}
        className={`generic-select ${inputClassName} ${selectClassName}`}
        onFocus={() => setSelectClassName('select-focus-border')}
        onBlur={() => setSelectClassName('')}
        components={
          components
            ? { ...defaultComponents, ...components }
            : defaultComponents
        }
        isClearable={clearable}
        hideSelectedOptions={false}
        classNamePrefix={'generic-select-prefix'}
        noOptionsMessage={noOptionsMessage}
        loadOptions={debounceFunc}
        cacheOptions={false}
        filterOption={false}
        id={props.id && props.id}
        role="searchbox"
        aria-label={props.labelText && props.labelText}
      />
    </>
  );
};

export default AutoSuggestionSelectComponent;
export { selectComponents as components };
