import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../components/layout/AuthContext';
import PrivateRoutes from './PrivateRoutes';

const Layout = lazy(() => import('../components/layout/Layout'));
const Login = lazy(() => import('../views/login/Login'));
const Home = lazy(() => import('../views/home/Home'));
const Contact = lazy(() => import('../views/contact/ContactUs'));
const Dashboard = lazy(() => import('../views/login/dashboard/Dashboard'));
const Registration = lazy(() => import('../views/registration/Registration'));
const Details = lazy(() => import('../views/details/Details'));
const ShopGrid = lazy(() => import('../views/shopgrid/ShopGrid'));
const AdminRouteOutlet = lazy(() => import('./AdminRouteOutlet'));
const AdminVendors = lazy(() => import('../views/admin/vendors'));
const AdminUsers = lazy(() => import('../views/admin/users').then(module => ({ default: module.Members })));
const AdminAddUser = lazy(() => import('../views/admin/users').then(module => ({ default: module.AddUser })));
const AdminProducts = lazy(() => import('../views/admin/products'));
const AdminSettings = lazy(() => import('../views/admin/settings'));
const AboutUs = lazy(() => import('../views/aboutus/AboutUs'));
const Blogs = lazy(() => import('../views/blog/Blog'));
const Categories = lazy(() => import('../views/admin/settings/categories'));
const AdminDashboard = lazy(() => import('../views/admin/dashbaord'));
const PartnerVendor = lazy(() => import('../views/aboutus/PartnerVendor'));
const Subcategories = lazy(() => import('../views/admin/settings/Subcategories'));
const Hospitals = lazy(() => import('../views/admin/hospitals'));
const Enquiry = lazy(() => import('../views/admin/enquiry'));
const Blog = lazy(() => import('../views/admin/blogs'));
const ForgotPassword = lazy(() => import('../views/login/forgotpassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../views/login/resetpassword/ResetPassword'));

const AppRoutes = () => {
  let auth = { 'token': false };
  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* ADMIN ROUTES */}
            <Route path="/admin" element={<AdminRouteOutlet />}>
              <Route element={<AdminDashboard />} path="/admin/dashboard" exact />
              <Route element={<AdminVendors />} path="/admin/vendors" exact />
              <Route element={<AdminVendors />} path="/admin/vendors/add" exact />
              <Route element={<AdminUsers />} path="/admin/members" exact />
              <Route element={<AdminAddUser />} path="/admin/users/add" exact />
              <Route element={<AdminProducts />} path="/admin/products" exact />
              <Route element={<AdminSettings />} path="/admin/settings" exact />
              <Route element={<Categories />} path="/admin/categories" exact />
              <Route element={<Subcategories />} path="/admin/subcategories" exact />
              <Route element={<Hospitals />} path="/admin/hospitals" exact />
              <Route element={<Enquiry />} path="/admin/enquiry" exact />
              <Route element={<Blog />} path="/admin/blogs" exact />
            </Route>

            <Route path="/" element={<Layout />}>
              <Route element={<PrivateRoutes />}>
                <Route element={<Home />} path="/" exact />
                <Route element={<Dashboard />} path="/dashboard" />
              </Route>
              <Route element={<Login />} path="/login" />
              <Route element={<Home />} path="/home" />
              <Route element={<AboutUs />} path="/about" />
              <Route element={<Blogs />} path="/blogs" />
              <Route element={<Blogs />} path="/blogs/:id" />
              <Route element={<PartnerVendor />} path="/partner-with-us" />
              <Route element={<Contact />} path="/contact" />
              <Route element={<ShopGrid />} path="/products/:id" />
              <Route element={<Registration />} path="/registration" />
              <Route element={<ForgotPassword />} path="/forgotpassword" />
              <Route element={<ResetPassword />} path="/resetpassword" />
              <Route element={<Details />} path="/details/:id" />
            </Route>
          </Routes>
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
