import React from 'react';
import ReactDOM from 'react-dom/client';
// import './styles/bootstrap.css'
// import '../node_modules/react-bootstrap/'
import './styles/themify-icons.css'
//import './styles/animate.css'
import './styles/owl-carousel.css'
import './styles/font-awesome.css'

import './styles/reset.css'
import './style.css';
import './styles/responsive.css'
import './index.scss';
import ReactGA from "react-ga4";
import { BASE_URL } from './common/constants';

import App from './App';
//import reportWebVitals from './reportWebVitals';

if(BASE_URL === 'https://medimarket.co.in/') {
  ReactGA.initialize("G-WNZC2QMRTS");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
});
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
