import React from 'react';
import { Route, Switch } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
//import "bootstrap/dist/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';
import { setAuthToken } from './services/apiService';

import Layout from "./components/layout/Layout";
import AppRoutes from './services/AppRoutes';

const App = () => {
    setAuthToken();
    return (<>
            <AppRoutes />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
        />
        </>
        );
    };
    export default App;