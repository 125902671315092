import ScrollToTop  from 'react-scroll-up'
import {HEADER_EMAIL, HEADER_PHONE, BASE_URL} from '../../common/constants'
import {
	FaAngleUp
  } from 'react-icons/fa';
const Footer = () => (
  <>
    <footer className="footer">
		<div className="footer-top section">
			<div className="container">
				<div className="row">
					<div className="col-lg-5 col-md-6 col-12">

						<div className="single-footer about">
							{/* <p>
							<a href="/home"><img src={`${BASE_URL}images/medworld_new_logo.png`} alt="logo" /></a>
							</p> */}
							{/* <p className="text">Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue,  magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p> */}
							<p className="call">Got Question? Call us<span><a href={`tel:${HEADER_PHONE}`} >{HEADER_PHONE}</a></span></p>
						</div>

					</div>
					<div className="col-lg-2 col-md-6 col-12">

						<div className="single-footer links">
							<h4>Information</h4>
							<ul>
								<li><a href="/about">About Us</a></li>
								{/* <li><a href="#">Faq</a></li> */}
								{/* <li><a href="#">Terms & Conditions</a></li> */}
								{/* <li><a href="#">Contact Us</a></li> */}
								{/* <li><a href="#">Help</a></li> */}
							</ul>
						</div>

					</div>
					<div className="col-lg-2 col-md-6 col-12">

						<div className="single-footer links">
							<h4>Customer Service</h4>
							<ul>
								{/* <li><a href="#">Payment Methods</a></li> */}
								{/* <li><a href="#">Money-back</a></li> */}
								{/* <li><a href="#">Returns</a></li> */}
								{/* <li><a href="#">Shipping</a></li> */}
								{/* <li><a href="#">Privacy Policy</a></li> */}
							</ul>
						</div>

					</div>
					<div className="col-lg-3 col-md-6 col-12">

						<div className="single-footer social">
							<h4>Get In Touch</h4>

							<div className="contact">
								<ul>
									<li><b>Pune Office</b></li>
									<li>603, White Square, Hinjewadi-Wakad Road Bridge, Wakad, Pune, Maharashtra 411057</li>
									<li>contact@medimarket.co.in</li>
									<li>+91 930 984 4601</li>
								</ul>
							</div>

							{/* <ul>
								<li><a href="#"><i className="ti-facebook"></i></a></li>
								<li><a href="#"><i className="ti-twitter"></i></a></li>
								<li><a href="#"><i className="ti-flickr"></i></a></li>
								<li><a href="#"><i className="ti-instagram"></i></a></li>
							</ul> */}
						</div>

					</div>
				</div>
			</div>
		</div>

		<div className="copyright">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="left">
								<p>Copyright © {new Date().getFullYear()} <a href='https://medworldsolutions.in/'>Medworld Solutions Pvt Ltd.</a> - All Rights Reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
  <ScrollToTop smooth showUnder={160} className="scrollUp" id="scrollUp">
    <span><FaAngleUp /></span>
  </ScrollToTop>
  </>
  );
export default Footer;
    
const footerStyle = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '1rem',
    marginTop: 'auto', // Push the footer to the bottom of the container
};