//this component is used as a container for showing multiple selected values for multi select dropdowns
import React from 'react';
import { components } from 'react-select';

export const MultipleValuesContainer = ({ index, getValue, ...props }) => {
  const maxToShow = 1;
  const overflow = getValue().slice(maxToShow).length;

  return index < maxToShow ? (
    <components.MultiValue {...props} cropWithEllipsis={false} />
  ) : index === maxToShow ? (
    <div className="multi-value-num-options">+{overflow}</div>
  ) : null;
};
