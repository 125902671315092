//Variable from ENV
export const API_END_POINT = process.env.REACT_APP_API_END_POINT
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const s3BASE_URL = process.env.REACT_APP_S3_BASE_URL

//Application variable 
// Header constants
export const HEADER_EMAIL = "contact@medimarket.co.in"
export const HEADER_PHONE = "+91 930 984 4601"


