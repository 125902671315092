import axios from 'axios';
import { useAuth } from '../components/layout/AuthContext';
import { API_END_POINT } from '../common/constants';

export const apiService = axios.create({
  baseURL: API_END_POINT,
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*'
  },
}); 

apiService.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  localStorage.setItem('MDtoken', null);
  localStorage.removeItem('authState');
  window.location.href = '/login';
 }
 return error;
});

export const setAuthToken = () => {
  const token = localStorage.getItem('MDtoken') || null;
  if (token) {
    apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiService.defaults.headers.common['Authorization'];
  }
};