import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { apiService, setAuthToken } from '../../services/apiService';
import { useAuth } from '../layout/AuthContext';
import { useNavigate } from 'react-router-dom';


import { HEADER_EMAIL, HEADER_PHONE, BASE_URL } from '../../common/constants'
import AutoSuggestionSelectComponentAsync, {
  components,
} from '../auto-suggestion-select/AsyncSelect';

const Header = () => {
  const { dispatch } = useAuth();
  const { state } = useAuth();
  const navigate = useNavigate();

  const onLogout = async () => {
    await apiService.get('/logout');
    localStorage.setItem('MDtoken', null);
    dispatch({ type: 'LOGOUT', payload: null });
  }

  const searchNames = async (input) => {
    try {
      if (!input || input.length < 3) {
        return [];
      }
      const url = `/search-products?search=${input}`;
      const response = await apiService.get(url);
      const namesList = [...response.data.data].map((val) => {
        return { label: val.name, value: val.id , type: val.type};
      });
      //namesList.unshift({ label: input, value: input });
      return namesList;
    } catch (error) {
      return null;
    }
  };

  const onChangeHandler = (e) => {
    if (e.value && e.type === 'products') {
      navigate(`/details/${e.value}`);
    } else{
      navigate(`/products/${e.value}`);
    }
  };

  return (
    <>
      <header className="header shop">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-12">
                <div className="top-left">
                  <ul className="list-main">
                    <li><i className="ti-headphone-alt"></i> {HEADER_PHONE}</li>
                    <li><i className="ti-email"></i> {HEADER_EMAIL}</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-12">
                <div className="right-content">
                  <ul className="list-main">
                  <li><i className="ti-link"></i> <a href="/Partner-with-us">PARTNER WITH US</a></li>
                    {state.isAuthenticated ? (
                      <li><i className="ti-power-off"></i><a href="#" onClick={onLogout}>Logout</a></li>
                    ) : (
                      <li><i className="ti-power-off"></i><a href="/login">Login</a></li>
                    )}
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="middle-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-12">
                <div className="logo">
                  <a href="/"><img src= {`${BASE_URL}images/logo.png`} alt="logo" /></a>
                </div>
                <div className="search-top">
                  <div className="top-search"><a href="#0"><i className="ti-search"></i></a></div>
                  <div className="search-top">
                    <form className="search-form">
                      <input type="text" placeholder="Search here..." name="search" />
                      <button value="search" type="submit"><i className="ti-search"></i></button>
                    </form>
                  </div>
                </div>
                <div className="mobile-nav"></div>
              </div>
              <div className="col-lg-8 col-md-7 col-12">
                <div className="search-bar-top">
                  <div className="search-bar">
                    {/* <select>
									<option selected="selected">All Category</option>
									<option>watch</option>
									<option>mobile</option>
									<option>kid’s item</option>
								</select> */}
                    <form>
                      {/* <input name="search" placeholder="Search Products Here....." type="search" />
                       */}
                      <AutoSuggestionSelectComponentAsync
                        onChange={onChangeHandler}
                        inputClassName=""
                        clearable={true}
                        // components={{
                        //   DropdownIndicator: () => null,
                        //   IndicatorSeparator: () => null,
                        // }} becuase mobile click event not happen
                        placeholder="For product search click on arrow....."
                        value=''
                        getOptionLabel={option =>
                          `${option.type === 'sub_category' ? 'Category' : 'Product'} - ${option.label}`
                        }
                        fetchOptions={searchNames}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                      />

                      {/* <button className="btnn"><i className="ti-search"></i></button> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-inner">
          <div className="container">
            <div className="cat-nav-head">
              <Navbar expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto">
                    <ul className="nav main-menu menu navbar-nav">
                      <li>
                        <Nav.Link href='/home'>Home</Nav.Link>
                      </li>
                      <li>
                        <Nav.Link href='/about'>About us</Nav.Link>
                      </li>
                      <li>
                        <Nav.Link href='/home#software'>Software</Nav.Link>
                      </li>
                      <li>
                        <Nav.Link href='/home#services'>Services</Nav.Link>
                      </li>
                      <li>
                        <Nav.Link href='/home#equipment'>Equipment</Nav.Link>
                      </li>
                      <li>
                        <Nav.Link href='/blogs'>Blog</Nav.Link>
                      </li>
                      {state.isAuthenticated ? (
                        <li><a href="#">Profile<i class="ti-angle-down"></i></a>
                        <ul className='dropdown'>
                          <li><Nav.Link href='/profile'>Profile Details</Nav.Link></li>
                          <li><Nav.Link href="#" onClick={onLogout}>Logout</Nav.Link></li>
                        </ul>
                      </li>
                      ) : (<>
                        <li>
                          <Nav.Link href='/contact'>Contact</Nav.Link>
                        </li>
                      </>
                      )}

                    </ul>

                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
      <header className="header shop">
      </header>
    </>
  )
}
export default Header;