import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
const Layout = () => (
  <div style={layoutStyle}>
    <Header />
    <main style={mainStyle}><Outlet /></main>
    <Footer />
  </div>
);
export default Layout;
// Styles
const layoutStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh', // Set minimum height to fill the viewport
};
const mainStyle = {
  flex: 1, // Allow main content to take remaining vertical space
  padding: '1rem',
};